<template>
  <div>
    <v-row class="d-flex align-center">
      <v-col cols="3">
        <ProductSearch @input="setProduct($event)" clearable />
      </v-col>

      <v-col cols="3">
        <CompanySelect
          v-model="searchParams.company_id"
          clearable
          :item-value="(val) => val.id"
        />
      </v-col>

      <v-col cols="3">
        <v-select
          v-model="searchParams.origin"
          :items="origins"
          label="Origem"
          dense
        />
      </v-col>

      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-auto text-capitalize"
            color="primary"
            text
            dark
            v-bind="attrs"
            v-on="on"
            small
          >
            Editar Colunas
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group>
            <template v-for="(item, index) in allHeaders">
              <v-list-item
                :key="index"
                :value="item"
                active-class="deep-purple--text text--accent-4"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox v-model="headers" :value="item" />
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-row>

    <v-row class="d-flex align-center">
      <v-col cols="3">
        <app-text-field
          v-model="searchParams.start_at"
          type="date"
          label="Data Inicial"
        />
      </v-col>
      <v-col cols="3">
        <app-text-field
          v-model="searchParams.end_at"
          type="date"
          label="Data Inicial"
        />
      </v-col>

      <v-btn @click="select()" small color="primary">
        <v-icon left> mdi-magnify </v-icon>
        Pesquisar
      </v-btn>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          disable-sort
          dense
          :headers="headers"
          :items="transactions.data"
          :items-per-page="-1"
        >
          <template v-slot:[`item.origin`]="{ item }">
            {{ getShortcut(item.origin).text }}

            <template v-if="getShortcut(item.origin).name">
              <app-shortcut-btn
                v-if="getShortcut(item.origin).params(item)"
                :name="getShortcut(item.origin).name"
                :params="getShortcutParams(item)"
              />
            </template>
          </template>
          <template v-slot:[`item.company`]="{ item }">
            <CompanyChip :company="item.company" />
          </template>
          <template v-slot:[`item.product`]="{ item }">
            {{ item.product.name }} |
            <small class="text--disabled">#{{ item.product.code }} </small>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ $format.dateBr(item.date) }}
          </template>

          <template v-slot:[`item.quantity_available`]="{ item }">
            {{ $format.decimal(item.quantity_available) }}
          </template>
          <template v-slot:[`item.quantity_reserved`]="{ item }">
            {{ $format.decimal(item.quantity_reserved) }}
          </template>
          <template v-slot:[`item.quantity_expedition`]="{ item }">
            {{ $format.decimal(item.quantity_expedition) }}
          </template>
          <template v-slot:[`item.quantity_physic`]="{ item }">
            {{ $format.decimal(item.quantity_physic) }}
          </template>
          <template v-slot:[`item.quantity_transfer`]="{ item }">
            {{ $format.decimal(item.quantity_transfer) }}
          </template>
          <template v-slot:[`item.balance_available`]="{ item }">
            {{ $format.decimal(item.balance_available) }}
          </template>
          <template v-slot:[`item.balance_reserved`]="{ item }">
            {{ $format.decimal(item.balance_reserved) }}
          </template>
          <template v-slot:[`item.balance_expedition`]="{ item }">
            {{ $format.decimal(item.balance_expedition) }}
          </template>
          <template v-slot:[`item.balance_physic`]="{ item }">
            {{ $format.decimal(item.balance_physic) }}
          </template>
          <template v-slot:[`item.balance_transfer`]="{ item }">
            {{ $format.decimal(item.balance_transfer) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div :class="`${statusData[item.status].color}--text`">
              {{ statusData[item.status].text }}
            </div>
          </template>
        </v-data-table>

        <app-pagination @click="select($event)" :data="transactions" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import ProductSearch from "@/components/product/ui/ProductSearch.vue";

export default {
  components: {
    CompanyChip,
    CompanySelect,
    ProductSearch,
  },
  data() {
    return {
      origins: [
        {
          text: "Entrega",
          value: "delivery",
          // shortcut: { name: "", params: "" },
        },
        {
          text: "Venda",
          value: "sale",
          name: "SaleView",
          params: (item) => item.sale_id,
        },
        {
          text: "Inventario",
          value: "stockAudit",
          name: "stockAuditProducts",
          params: (item) => {
            return { id: item.stock_audit_id };
          },
        },
        {
          text: "Transferência",
          value: "transfer",
          name: "ViewStockTransfer",
          params: (item) => {
            return { id: item.stock_transfer_id };
          },
        },
        {
          text: "Compra",
          value: "purchase",
          name: "PurchaseForm",
          params: (item) => {
            return { id: item.purchase_id };
          },
        },
        {
          text: "Importação",
          value: "init",
          disabled: true,
        },
      ],

      searchParams: {},
      transactions: {},
      headers: [
        { text: "Origem", value: "origin" },
        { text: "Produto", value: "product" },
        { text: "Empresa", value: "company" },
        { text: "Data", value: "date", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],

      allHeaders: [
        { text: "Quantidade disponível", value: "quantity_available" },
        { text: "Quantidade reservado", value: "quantity_reserved" },
        { text: "Quantidade expedição", value: "quantity_expedition" },
        { text: "Quantidade físico", value: "quantity_physic" },
        { text: "Quantidade transferência", value: "quantity_transfer" },
        { text: "Total disponível", value: "balance_available" },
        { text: "Total reservado", value: "balance_reserved" },
        { text: "Total expedição", value: "balance_expedition" },
        { text: "Total físico", value: "balance_physic" },
        { text: "Total transferência", value: "balance_transfer" },
      ],

      statusData: {
        processed: {
          color: "green",
          text: "Processado",
        },

        canceled: {
          color: "error",
          text: "Cancelado",
        },
      },
    };
  },

  async created() {
    // await this.select();
  },

  methods: {
    async select(page = 1) {
      this.searchParams.page = page;
      this.$loading.start();
      await this.$http
        .index("stock/transactions", this.searchParams)
        .then((response) => {
          this.transactions = response.transactions;
          this.$loading.finish();
        });

      this.$loading.finish();
    },
    setProduct(product) {
      this.searchParams.product_id = product ? product.id : null;
      this.select();
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    getShortcut(origin) {
      return this.origins.find((item) => item.value == origin);
    },

    getShortcutParams(item) {
      return this.getShortcut(item.origin).params(item);

      // `company_id`,
      // `product_id`,
      //  `product_variant_id`,
      // `product_company_id`,
      //  `purchase_id`,
      // `stock_audit_id`,
      //  `sale_id`,
      // `stock_transfer_id`,
      //  `origin`,
      // if (item.origin == "sale") {
      //   return { id: item.sale_id };
      // }
      // if (item.origin == "stockAudit") {
      //   return { id: item.stock_audit_id };
      // }
      // if (item.origin == "transfer") {
      //   return { id: item.stock_transfer_id };
      // }
    },
  },
};
</script>

<style>
</style>
