var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"3"}},[_c('ProductSearch',{attrs:{"clearable":""},on:{"input":function($event){return _vm.setProduct($event)}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('CompanySelect',{attrs:{"clearable":"","item-value":function (val) { return val.id; }},model:{value:(_vm.searchParams.company_id),callback:function ($$v) {_vm.$set(_vm.searchParams, "company_id", $$v)},expression:"searchParams.company_id"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.origins,"label":"Origem","dense":""},model:{value:(_vm.searchParams.origin),callback:function ($$v) {_vm.$set(_vm.searchParams, "origin", $$v)},expression:"searchParams.origin"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto text-capitalize",attrs:{"color":"primary","text":"","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Editar Colunas ")])]}}])},[_c('v-list',[_c('v-list-item-group',[_vm._l((_vm.allHeaders),function(item,index){return [_c('v-list-item',{key:index,attrs:{"value":item,"active-class":"deep-purple--text text--accent-4"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":item},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}})],1)],1)]})],2)],1)],1)],1),_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"3"}},[_c('app-text-field',{attrs:{"type":"date","label":"Data Inicial"},model:{value:(_vm.searchParams.start_at),callback:function ($$v) {_vm.$set(_vm.searchParams, "start_at", $$v)},expression:"searchParams.start_at"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('app-text-field',{attrs:{"type":"date","label":"Data Inicial"},model:{value:(_vm.searchParams.end_at),callback:function ($$v) {_vm.$set(_vm.searchParams, "end_at", $$v)},expression:"searchParams.end_at"}})],1),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.select()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Pesquisar ")],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-sort":"","dense":"","headers":_vm.headers,"items":_vm.transactions.data,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.origin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getShortcut(item.origin).text)+" "),(_vm.getShortcut(item.origin).name)?[(_vm.getShortcut(item.origin).params(item))?_c('app-shortcut-btn',{attrs:{"name":_vm.getShortcut(item.origin).name,"params":_vm.getShortcutParams(item)}}):_vm._e()]:_vm._e()]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('CompanyChip',{attrs:{"company":item.company}})]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name)+" | "),_c('small',{staticClass:"text--disabled"},[_vm._v("#"+_vm._s(item.product.code)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.date))+" ")]}},{key:"item.quantity_available",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_available))+" ")]}},{key:"item.quantity_reserved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_reserved))+" ")]}},{key:"item.quantity_expedition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_expedition))+" ")]}},{key:"item.quantity_physic",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_physic))+" ")]}},{key:"item.quantity_transfer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_transfer))+" ")]}},{key:"item.balance_available",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_available))+" ")]}},{key:"item.balance_reserved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_reserved))+" ")]}},{key:"item.balance_expedition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_expedition))+" ")]}},{key:"item.balance_physic",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_physic))+" ")]}},{key:"item.balance_transfer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_transfer))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:((_vm.statusData[item.status].color) + "--text")},[_vm._v(" "+_vm._s(_vm.statusData[item.status].text)+" ")])]}}],null,true)}),_c('app-pagination',{attrs:{"data":_vm.transactions},on:{"click":function($event){return _vm.select($event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }